.rich-text-editor-parent {
}

.rich-text-editor-parent .editor-container {
  border-radius: 2px !important;
  width: 100% !important;
  color: #000 !important;
  position: relative !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  text-align: left !important;
  border-width: 1px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.rich-text-editor-parent.readonly .editor-container {
  border-width: 0;
}

.rich-text-editor-parent .editor-inner {
  background: #fff !important;
  position: relative !important;
}

.rich-text-editor-parent .editor-input {
  min-height: 150px !important;
  resize: none !important;
  font-size: 15px !important;
  caret-color: rgb(5, 5, 5) !important;
  position: relative !important;
  tab-size: 1 !important;
  outline: 0 !important;
  padding: 15px 10px !important;
  caret-color: #444 !important;
}

.rich-text-editor-parent.readonly .editor-input {
  padding-top: 0 !important;
  padding-left: 0 !important;
}

pre::-webkit-scrollbar {
  background: transparent !important;
  width: 10px !important;
}

pre::-webkit-scrollbar-thumb {
  background: #999 !important;
}

.rich-text-editor-parent .toolbar {
  display: flex !important;
  margin-bottom: 1px !important;
  background: #fff !important;
  padding: 4px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  vertical-align: middle !important;
}

.rich-text-editor-parent .toolbar button.toolbar-item {
  border: 0 !important;
  display: flex !important;
  background: none !important;
  border-radius: 10px !important;
  padding: 8px !important;
  cursor: pointer !important;
  vertical-align: middle !important;
}

.rich-text-editor-parent .toolbar button.toolbar-item:disabled {
  cursor: not-allowed !important;
}

.rich-text-editor-parent .toolbar button.toolbar-item.spaced {
  margin-right: 2px !important;
}

.rich-text-editor-parent .toolbar button.toolbar-item i.format {
  background-size: contain !important;
  display: inline-block !important;
  height: 18px !important;
  width: 18px !important;
  margin-top: 2px !important;
  vertical-align: -0.25em !important;
  display: flex !important;
  opacity: 0.6 !important;
}

.rich-text-editor-parent .toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2 !important;
}

.rich-text-editor-parent .toolbar button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3) !important;
}

.rich-text-editor-parent .toolbar button.toolbar-item.active i {
  opacity: 1 !important;
}

.rich-text-editor-parent .toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee !important;
}

.rich-text-editor-parent .toolbar .divider {
  width: 1px !important;
  background-color: #eee !important;
  margin: 0 4px !important;
}

.rich-text-editor-parent .toolbar select.toolbar-item {
  border: 0 !important;
  display: flex !important;
  background: none !important;
  border-radius: 10px !important;
  padding: 8px !important;
  vertical-align: middle !important;
  width: 70px !important;
  font-size: 14px !important;
  color: #777 !important;
  text-overflow: ellipsis !important;
}

.rich-text-editor-parent .toolbar select.code-language {
  text-transform: capitalize !important;
  width: 130px !important;
}

.rich-text-editor-parent .toolbar .toolbar-item .text {
  display: flex !important;
  line-height: 20px !important;
  width: 200px !important;
  vertical-align: middle !important;
  font-size: 14px !important;
  color: #777 !important;
  text-overflow: ellipsis !important;
  width: 70px !important;
  overflow: hidden !important;
  height: 20px !important;
  text-align: left !important;
}

.rich-text-editor-parent .toolbar .toolbar-item .icon {
  display: flex !important;
  width: 20px !important;
  height: 20px !important;
  user-select: none !important;
  margin-right: 8px !important;
  line-height: 16px !important;
  background-size: contain !important;
}

.rich-text-editor-parent .toolbar i.chevron-down {
  margin-top: 3px !important;
  width: 16px !important;
  height: 16px !important;
  display: flex !important;
  user-select: none !important;
}

.rich-text-editor-parent .toolbar i.chevron-down.inside {
  width: 16px !important;
  height: 16px !important;
  display: flex !important;
  margin-left: -25px !important;
  margin-top: 11px !important;
  margin-right: 10px !important;
  pointer-events: none !important;
}

.rich-text-editor-parent i.chevron-down {
  background-color: transparent !important;
  background-size: contain !important;
  display: inline-block !important;
  height: 8px !important;
  width: 8px !important;
  background-image: url(images/icons/chevron-down.svg) !important;
}

.rich-text-editor-parent #block-controls button:hover {
  background-color: #efefef !important;
}

.rich-text-editor-parent #block-controls button:focus-visible {
  border-color: blue !important;
}

.rich-text-editor-parent #block-controls span.block-type {
  background-size: contain !important;
  display: block !important;
  width: 18px !important;
  height: 18px !important;
  margin: 2px !important;
}

.rich-text-editor-parent #block-controls span.block-type.paragraph {
  background-image: url(images/icons/text-paragraph.svg) !important;
}

.rich-text-editor-parent #block-controls span.block-type.h1 {
  background-image: url(images/icons/type-h1.svg) !important;
}

.rich-text-editor-parent #block-controls span.block-type.h2 {
  background-image: url(images/icons/type-h2.svg) !important;
}

.rich-text-editor-parent #block-controls span.block-type.quote {
  background-image: url(images/icons/chat-square-quote.svg) !important;
}

.rich-text-editor-parent #block-controls span.block-type.ul {
  background-image: url(images/icons/list-ul.svg) !important;
}

.rich-text-editor-parent #block-controls span.block-type.ol {
  background-image: url(images/icons/list-ol.svg) !important;
}

.rich-text-editor-parent #block-controls span.block-type.code {
  background-image: url(images/icons/code.svg) !important;
}

.rich-text-editor-dropdown {
  z-index: 5 !important;
  display: block !important;
  position: absolute !important;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
  border-radius: 8px !important;
  min-width: 100px !important;
  min-height: 40px !important;
  background-color: #fff !important;
}

.rich-text-editor-dropdown .item {
  margin: 0 8px 0 8px !important;
  padding: 8px !important;
  color: #050505 !important;
  cursor: pointer !important;
  line-height: 16px !important;
  font-size: 15px !important;
  display: flex !important;
  align-content: center !important;
  flex-direction: row !important;
  flex-shrink: 0 !important;
  justify-content: space-between !important;
  background-color: #fff !important;
  border-radius: 8px !important;
  border: 0 !important;
  min-width: 268px !important;
}

.rich-text-editor-dropdown .item .active {
  display: flex !important;
  width: 20px !important;
  height: 20px !important;
  background-size: contain !important;
}

.rich-text-editor-dropdown .item:first-child {
  margin-top: 8px !important;
}

.rich-text-editor-dropdown .item:last-child {
  margin-bottom: 8px !important;
}

.rich-text-editor-dropdown .item:hover {
  background-color: #eee !important;
}

.rich-text-editor-dropdown .item .text {
  display: flex !important;
  line-height: 20px !important;
  flex-grow: 1 !important;
  width: 200px !important;
}

.rich-text-editor-dropdown .item .icon {
  display: flex !important;
  width: 20px !important;
  height: 20px !important;
  user-select: none !important;
  margin-right: 12px !important;
  line-height: 16px !important;
  background-size: contain !important;
}

.rich-text-editor-parent .link-editor {
  position: absolute !important;
  z-index: 100 !important;
  top: -10000px !important;
  left: -10000px !important;
  margin-top: -6px !important;
  max-width: 300px !important;
  width: 100% !important;
  opacity: 0 !important;
  background-color: #fff !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3) !important;
  border-radius: 8px !important;
  transition: opacity 0.5s !important;
}

.rich-text-editor-parent .link-editor .link-input {
  display: block !important;
  width: calc(100% - 24px) !important;
  box-sizing: border-box !important;
  margin: 8px 12px !important;
  padding: 8px 12px !important;
  border-radius: 15px !important;
  background-color: #eee !important;
  font-size: 15px !important;
  color: rgb(5, 5, 5) !important;
  border: 0 !important;
  outline: 0 !important;
  position: relative !important;
  font-family: inherit !important;
}

.rich-text-editor-parent .link-editor div.link-edit {
  background-image: url(images/icons/pencil-fill.svg) !important;
  background-size: 16px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  width: 35px !important;
  vertical-align: -0.25em !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  cursor: pointer !important;
}

.rich-text-editor-parent .link-editor .link-input a {
  color: rgb(33, 111, 219) !important;
  text-decoration: none !important;
  display: block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  margin-right: 30px !important;
  text-overflow: ellipsis !important;
}

.rich-text-editor-parent .link-editor .link-input a:hover {
  text-decoration: underline !important;
}

.rich-text-editor-parent .link-editor .button {
  width: 20px !important;
  height: 20px !important;
  display: inline-block !important;
  padding: 6px !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  margin: 0 2px !important;
}

.rich-text-editor-parent .link-editor .button.hovered {
  width: 20px !important;
  height: 20px !important;
  display: inline-block !important;
  background-color: #eee !important;
}

.rich-text-editor-parent .link-editor .button i,
.rich-text-editor-parent .actions i {
  background-size: contain !important;
  display: inline-block !important;
  height: 20px !important;
  width: 20px !important;
  vertical-align: -0.25em !important;
}

.rich-text-editor-parent i.undo {
  background-image: url(images/icons/arrow-counterclockwise.svg) !important;
}

.rich-text-editor-parent i.redo {
  background-image: url(images/icons/arrow-clockwise.svg) !important;
}

.rich-text-editor-parent .icon.paragraph {
  background-image: url(images/icons/text-paragraph.svg) !important;
}

.rich-text-editor-parent .icon.large-heading,
.rich-text-editor-parent .icon.h1 {
  background-image: url(images/icons/type-h1.svg) !important;
}

.rich-text-editor-parent .icon.small-heading,
.rich-text-editor-parent .icon.h2 {
  background-image: url(images/icons/type-h2.svg) !important;
}

.rich-text-editor-parent .icon.bullet-list,
.rich-text-editor-parent .icon.ul {
  background-image: url(images/icons/list-ul.svg) !important;
}

.rich-text-editor-parent .icon.numbered-list,
.rich-text-editor-parent .icon.ol {
  background-image: url(images/icons/list-ol.svg) !important;
}

.rich-text-editor-parent .icon.quote {
  background-image: url(images/icons/chat-square-quote.svg) !important;
}

.rich-text-editor-parent .icon.code {
  background-image: url(images/icons/code.svg) !important;
}

.rich-text-editor-parent i.bold {
  background-image: url(images/icons/type-bold.svg) !important;
}

.rich-text-editor-parent i.italic {
  background-image: url(images/icons/type-italic.svg) !important;
}

.rich-text-editor-parent i.underline {
  background-image: url(images/icons/type-underline.svg) !important;
}

.rich-text-editor-parent i.strikethrough {
  background-image: url(images/icons/type-strikethrough.svg) !important;
}

.rich-text-editor-parent i.code {
  background-image: url(images/icons/code.svg) !important;
}

.rich-text-editor-parent i.link {
  background-image: url(images/icons/link.svg) !important;
}

.rich-text-editor-parent i.left-align {
  background-image: url(images/icons/text-left.svg) !important;
}

.rich-text-editor-parent i.center-align {
  background-image: url(images/icons/text-center.svg) !important;
}

.rich-text-editor-parent i.right-align {
  background-image: url(images/icons/text-right.svg) !important;
}

.rich-text-editor-parent i.justify-align {
  background-image: url(images/icons/justify.svg) !important;
}
