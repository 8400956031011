.successStatusBtn {
  border-color: #16db65;
  background-color: #16db65;
}

.successStatusBtn:hover, .successStatusBtn:focus {
  border-color: #0be881;
  background-color: #0be881;
}

.statusBtn, .successStatusBtn {
  width: 100%;
}

.viewBtn, .statusBtn, .successStatusBtn {
  font-weight: bold;
}
