.positiveCostSaving {
  color: #16db65;
}

.negativeCostSaving {
  color: #FF4D4F;
}

.subHeading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
