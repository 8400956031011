.rich-text-editor-parent .ltr {
  text-align: left !important;
}

.rich-text-editor-parent .rtl {
  text-align: right !important;
}

.rich-text-editor-parent .editor-placeholder {
  color: #999 !important;
  overflow: hidden !important;
  position: absolute !important;
  text-overflow: ellipsis !important;
  top: 15px !important;
  left: 10px !important;
  font-size: 15px !important;
  user-select: none !important;
  display: inline-block !important;
  pointer-events: none !important;
}

.rich-text-editor-parent .editor-text-bold {
  font-weight: bold !important;
}

.rich-text-editor-parent .editor-text-italic {
  font-style: italic !important;
}

.rich-text-editor-parent .editor-text-underline {
  text-decoration: underline !important;
}

.rich-text-editor-parent .editor-text-strikethrough {
  text-decoration: line-through !important;
}

.rich-text-editor-parent .editor-text-underlineStrikethrough {
  text-decoration: underline line-through !important;
}

.rich-text-editor-parent .editor-text-code {
  background-color: rgb(240, 242, 245) !important;
  padding: 1px 0.25rem !important;
  font-family: Menlo, Consolas, Monaco, monospace !important;
  font-size: 94% !important;
}

.rich-text-editor-parent .editor-link {
  color: rgb(33, 111, 219) !important;
  text-decoration: none !important;
}

.rich-text-editor-parent .editor-code {
  background-color: rgb(240, 242, 245) !important;
  font-family: Menlo, Consolas, Monaco, monospace !important;
  display: block !important;
  padding: 8px 8px 8px 52px !important;
  line-height: 1.53 !important;
  font-size: 13px !important;
  margin: 0 !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  tab-size: 2 !important;
  /* white-space: pre; */
  overflow-x: auto !important;
  position: relative !important;
}

.rich-text-editor-parent .editor-code:before {
  content: attr(data-gutter) !important;
  position: absolute !important;
  background-color: #eee !important;
  left: 0 !important;
  top: 0 !important;
  border-right: 1px solid #ccc !important;
  padding: 8px !important;
  color: #777 !important;
  white-space: pre-wrap !important;
  text-align: right !important;
  min-width: 25px !important;
}
.rich-text-editor-parent .editor-code:after {
  content: attr(data-highlight-language) !important;
  top: 0 !important;
  right: 3px !important;
  padding: 3px !important;
  font-size: 10px !important;
  text-transform: uppercase !important;
  position: absolute !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.rich-text-editor-parent .editor-tokenComment {
  color: slategray !important;
}

.rich-text-editor-parent .editor-tokenPunctuation {
  color: #999 !important;
}

.rich-text-editor-parent .editor-tokenProperty {
  color: #905 !important;
}

.rich-text-editor-parent .editor-tokenSelector {
  color: #690 !important;
}

.rich-text-editor-parent .editor-tokenOperator {
  color: #9a6e3a !important;
}

.rich-text-editor-parent .editor-tokenAttr {
  color: #07a !important;
}

.rich-text-editor-parent .editor-tokenVariable {
  color: #e90 !important;
}

.rich-text-editor-parent .editor-tokenFunction {
  color: #dd4a68 !important;
}

.rich-text-editor-parent .editor-paragraph {
  margin: 0 !important;
  margin-bottom: 8px !important;
  position: relative !important;
}

.rich-text-editor-parent .editor-paragraph:last-child {
  margin-bottom: 0 !important;
}

.rich-text-editor-parent .editor-heading-h1 {
  font-size: 24px !important;
  color: rgb(5, 5, 5) !important;
  font-weight: 400 !important;
  margin: 0 !important;
  margin-bottom: 12px !important;
  padding: 0 !important;
}

.rich-text-editor-parent .editor-heading-h2 {
  font-size: 15px !important;
  color: rgb(101, 103, 107) !important;
  font-weight: 700 !important;
  margin: 0 !important;
  margin-top: 10px !important;
  padding: 0 !important;
  text-transform: uppercase !important;
}

.rich-text-editor-parent .editor-quote {
  margin: 0 !important;
  margin-left: 20px !important;
  font-size: 15px !important;
  color: rgb(101, 103, 107) !important;
  border-left-color: rgb(206, 208, 212) !important;
  border-left-width: 4px !important;
  border-left-style: solid !important;
  padding-left: 16px !important;
}

.rich-text-editor-parent .editor-list-ol {
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 16px !important;
}

.rich-text-editor-parent .editor-list-ul {
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 16px !important;
}

.rich-text-editor-parent .editor-listitem {
  margin: 8px 32px 8px 32px !important;
}

.rich-text-editor-parent .editor-nested-listitem {
  list-style-type: none !important;
}

.rich-text-editor-parent .editor-list-ol .editor-listitem {
  list-style-type: decimal;
}

.rich-text-editor-parent .editor-list-ul .editor-listitem {
  list-style-type: disc;
}
